<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="800px"
    style="background-color: white"
  >
    <div class="bg-white poppins modal-root h-100">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit SKUs</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <div class="row">
          <div class="col-12 col-md-6">
            <v-select
              v-model="formData.currency_id"
              label="Currency"
              dense
              type="number"
              :min="0"
              :items="serverData.currencies"
              outlined
              :error-messages="currencyErrors"
              @input="$v.formData.currency_id.$touch()"
              @blur="$v.formData.currency_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.price"
              label="Price"
              dense
              type="number"
              :min="0"
              outlined
              :error-messages="priceErrors"
              @input="$v.formData.price.$touch()"
              @blur="$v.formData.price.$touch()"
            ></v-text-field>
          </div>
        </div>
        <h5 class="mt-7">Order SKUs</h5>
        <hr class="pb-5" />
        <div class="row">
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="selectedSku"
              label="Search SKU"
              dense
              :items="availableSkus"
              outlined
              item-text="systemCode"
              item-value="skuId"
              @change="onSelectItem"
            ></v-autocomplete>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <template v-if="isSkusPresent">
              <v-data-table
                :headers="skuTableHeader"
                :items="skus"
                item-key="skuId"
                class="elevation-1"
                show-select
                :value="selectedRows"
              >
                <template v-slot:item.quantity="{ item }">
                  <v-text-field
                    placeholder="Qty"
                    outlined
                    dense
                    hide-details
                    v-model="item.quantity"
                  >
                  </v-text-field>
                </template>
              </v-data-table>
            </template>
            <template v-else>
              <div class="h2 my-5 text-center">No SKU Found</div></template
            >
          </div>
        </div>

        <div class="d-flex align-center modal-action flex-wrap pt-4 mt-10">
          <div class="justify-center align-center d-flex col-12">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3 mx-2 order-1 mt-8 mb-3 mt-sm-0"
            >
              {{ isSkusPresent ? "Update" : "Close" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { SET_INTERNAL_ORDER_SKUS } from "@/core/services/store/returns.module";
export default {
  name: "EditSkus",
  props: ["items"],
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        currency_id: { required },
        price: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    skus: [],
    sku_search_text: null,
    suggestedItems: [],
    isLoading: false,
    selectedOrder: null,
    formData: { price: null, currency: null },
    selectedSku: null,
    selectedRows: [],
  }),
  computed: {
    isSkusPresent: function () {
      return !!this.skus.length;
    },
    currencyErrors: function () {
      return this.handleFormValidation("currency_id", this, "formData");
    },
    priceErrors: function () {
      return this.handleFormValidation("price", this, "formData");
    },
    orderSkus: function () {
      return this.items
        .find((item) => item.order_id === this.selectedOrder.order_id)
        .skus.map((item) => ({ ...item, valid: true }));
    },
    availableSkus: function () {
      return this.$store.getters.getRTRNAvailableSkus;
    },
    skuTableHeader: function () {
      return [
        { text: "SKU Id", align: "center", sortable: false, value: "skuId" },
        {
          text: "System code",
          align: "center",
          sortable: false,
          value: "systemCode",
        },
        {
          text: "Quantity",
          align: "center",
          sortable: false,
          value: "quantity",
          width: 100,
        },
      ];
    },
    serverData: function () {
      return this.$store.getters.getRTRNServerData;
    },
  },
  methods: {
    toggleModal(order) {
      if (!this.dialog) {
        this.selectedOrder = order;
        this.skus = [...this.orderSkus];
        this.selectedRows = [...this.orderSkus];
        // this.items
        //   .find((item) => item.order_id === this.selectedOrder.order_id)
        //   .skus.forEach((item) => {
        //     this.skus.push({ ...item, valid: true });
        //   });

        this.$store.commit(SET_INTERNAL_ORDER_SKUS, this.skus);
      } else if (this.dialog) {
        // check if atleast one valid sku present
        const isValid = this.selectedRows.some((item) => item.valid);
        if (this.isSkusPresent && !isValid) {
          Swal.fire({
            title: "Warning",
            text: "Please, select a sku!",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        //check if any order has invalid qty
        const isAnyOrderMissingQty = this.selectedRows.some(
          (item) => item.quantity === ""
        );
        if (this.isSkusPresent && isAnyOrderMissingQty) {
          Swal.fire({
            title: "Warning",
            text: "Enter valid SKU quantity",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        //check atleast one sku which has qty > 0
        const hasValidQty = this.selectedRows.every(
          (item) => Number(item.quantity) > 0
        );
        if (this.isSkusPresent && !hasValidQty) {
          Swal.fire({
            title: "Warning",
            text: "Order should contain atleast 1 SKU",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        let hasValidQty2 = true;
        this.selectedRows.forEach((item) => {
          const index = this.availableSkus.findIndex(
            (sku) => sku.skuId === item.skuId
          );
          if (index !== -1) {
            if (this.availableSkus[index].quantity < item.quantity) {
              hasValidQty2 = false;
            }
          }
        });
        if (!hasValidQty2) {
          Swal.fire({
            title: "Warning",
            text: "Quantity number exceed than original order",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        this.$store.commit(SET_INTERNAL_ORDER_SKUS, this.selectedRows);
        this.$emit("updateOrderDetails", {
          skus: this.selectedRows,
          others: this.formData,
        });
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    // onChangeSkus() {
    //   this.$store.commit(SET_INTERNAL_ORDER_SKUS, this.skus);
    // },
    onSelectItem() {
      //check if item already exists
      const index = this.skus.findIndex(
        (item) => item.skuId === this.selectedSku
      );
      if (index === -1) {
        const item = this.availableSkus.find(
          (item) => item.skuId === this.selectedSku
        );

        if (item.is_return_created) {
          Swal.fire({
            title: "Warning",
            text: "Return already created for this SKU",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        this.skus.push(item);
        this.selectedRows.push(item);
        this.selectedSku = null;
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.toggleModal();
    },
  },
};
</script>
